/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import { toast } from 'react-toastify'

import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout'
import FlexContainer from '../../components/FlexContainer'
import Wrapper from '../../components/Wrapper'
import Title from '../../components/Title'

import {
  Icon,
  BilletCode,
  BilletCodeAndCopy,
} from '../../components/pages/Campanhas/Confirmation/styles'
import { PAYMENT } from '../../utils/enum/payment'

import Copy from '../../images/icons/ic_copiar.svg'
import Download from '../../images/icons/ic_download.svg'

const Confirmation = ({ location }) => {
  const intl = useIntl()
  const payment = location.state && location.state.payment

  const billetInfo = location.state && location.state.reqRes

  const copyText = event => {
    let textarea = ''
    let result = ''
    try {
      textarea = document.createElement('textarea')
      textarea.setAttribute('readonly', true)
      textarea.setAttribute('contenteditable', true)
      textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = billetInfo.bankTicketCode

      document.body.appendChild(textarea)

      textarea.focus()
      textarea.select()

      const range = document.createRange()
      range.selectNodeContents(textarea)

      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)

      textarea.setSelectionRange(0, textarea.value.length)
      result = document.execCommand('copy')
      result = null
    } finally {
      document.body.removeChild(textarea)
      toast.success(
        intl.formatMessage({
          id: 'toast_messages.success.code_copy',
        })
      )
    }
  }

  const copyTextPix = text => {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    toast.success('Código copiado com sucesso!')
  }

  const setBase64QrCode = () => {
    let baseString = location.state.qrCode

    if (baseString.substring(0, 4) !== 'data') {
      baseString = `data:image/png;base64,${baseString}`
    }

    return baseString
  }

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "img-sucesso.png" }) {
        childImageSharp {
          fixed(width: 765, height: 419) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageMobile: file(relativePath: { eq: "img-sucesso.png" }) {
        childImageSharp {
          fluid(maxWidth: 765) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Wrapper>
        <FlexContainer
          css={theme => css`
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            padding: 90px 0 130px;

            ${theme.queries.medium} {
              padding: 110px 0 170px;
            }
          `}
        >
          <Title
            size="40"
            lineHeight="52"
            mobileSize="28"
            mobileHeight="35"
            css={theme => css`
              margin-bottom: 42px;
              text-align: center;
              width: 90%;

              ${theme.queries.medium} {
                width: 500px;
              }
            `}
          >
            {payment === PAYMENT.billet &&
              intl.formatMessage({
                id: 'pages.donation.success.billet',
              })}
            {payment === PAYMENT.pix &&
              intl.formatMessage({
                id: 'pages.donation.success.pix',
              })}
            {payment === PAYMENT.card &&
              intl.formatMessage({
                id: 'pages.donation.success.donation',
              })}
          </Title>

          {payment === PAYMENT.billet ? (
            <BilletCode>
              <h3>Código do boleto:</h3>
              <BilletCodeAndCopy>
                <span>{billetInfo.bankTicketCode}</span>
                <div className="icons">
                  <Icon onClick={copyText} useRef="copyIcon">
                    <Copy />
                    {billetInfo.bankTicketCode}
                  </Icon>
                  <Icon>
                    <a
                      href={billetInfo.bankTicketUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Download />
                    </a>
                  </Icon>
                </div>
              </BilletCodeAndCopy>
            </BilletCode>
          ) : null}

          {payment === PAYMENT.pix && (
            <BilletCode>
              <h3>Código do pix:</h3>
              <BilletCodeAndCopy>
                <span>{location.state.pixCode}</span>
                <div className="icons">
                  <Icon
                    onClick={() => copyTextPix(location.state.pixCode)}
                    useRef="copyIconPix"
                  >
                    <Copy />
                    {location.state.qrCode}
                  </Icon>
                </div>
              </BilletCodeAndCopy>

              <img
                id="qrcode"
                src={setBase64QrCode()}
                alt="QR Code"
                width="155"
                height="155"
              />
            </BilletCode>
          )}

          <Img
            loading="eager"
            fluid={data.imageMobile.childImageSharp.fluid}
            css={theme =>
              css`
                width: 100%;
                ${theme.queries.medium} {
                  display: none;
                }
              `
            }
          />
          <Img
            loading="eager"
            fixed={data.image.childImageSharp.fixed}
            css={theme =>
              css`
                ${theme.queries.small} {
                  display: none !important;
                }
              `
            }
          />
        </FlexContainer>
      </Wrapper>
    </Layout>
  )
}

Confirmation.propTypes = {
  location: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.string.isRequired).isRequired
  ).isRequired,
  payment: PropTypes.string.isRequired,
}

export default Confirmation
