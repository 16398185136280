import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Icon = styled.div`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    background-color: #e8e8e8;
    display: flex;
    border-radius: 100%;
    align-items: center;
    margin-left: 15px;
    cursor: pointer;
    overflow: hidden;
    font-size: 0;
    justify-content: center;

    svg {
      width: 15px;
      heith: 15px;
    }

    ${theme.queries.small} {
      width: 35px;
      height: 35px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  `}
`

export const BilletCode = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;

    h3 {
      font-family: 'Above', sans-serif;
      font-size: 30px;
      margin-bottom: 15px;
    }

    ${theme.queries.small} {
      h3 {
        font-family: 'Above', sans-serif;
        font-size: 25px;
        margin-bottom: 15px;
      }
    }
  `}
`

export const BilletCodeAndCopy = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-size: 18px;
      width: 40rem;
    }

    .icons {
      display: flex;
      flex-direction: row;
    }

    ${theme.queries.small} {
      flex-direction: column;
      justify-content: center;

      .icons {
        margin-top: 15px;
      }
      span {
        font-size: 13px;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `}
`
